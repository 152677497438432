import {
    camelCase,
    capitalize,
    isEmpty,
    isNaN,
    isNumber,
    last,
    replace,
    startCase,
    toNumber,
    trim,
    uniqWith
} from 'lodash'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

export function removeDuplicates(array, prop) {
    return array.filter((obj, index, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === index;
    });
}

export function pascalCase (value) {
    return startCase(camelCase(value)).replace(/ /g, '')
}

export function formatRailsErrors (errors) {
    let entries = []
    Object.entries(errors).forEach(([key, values]) => {
        values.forEach(value => {
            entries.push([ capitalize(last(key.replace(/_/g, ' ').split('.'))), value ])
        })
    })

    return uniqWith(entries, ([k1, v1], [k2, v2]) => k1 === k2 && v1 === v2).map(pair => pair.join(' '))
}

export function replaceKeys(object, pattern, replacement) {
    const entries = Object.entries(object).map(([key, value]) => [key.replace(pattern, replacement), value])
    return Object.fromEntries(entries)
}

export function getMatchedPattern(string, search) {
    const searchTokens = search.split(/\W+/)
    let regexTokens = []
    searchTokens.forEach(token => {
        if (token.length) {
            regexTokens.push(`\\b${token}`)
        }
    })

    if (regexTokens.length) {
        const pattern = new RegExp(regexTokens.join('|'), 'ig')
        return replace(string, pattern, (replacement) => `<strong>${replacement}</strong>`)
    }

    return string
}

export function numberToPhone (number) {
    const match = number.match(/(\d{0,3})(\d{3})(\d{4})$/)
    return match && match.slice(1, 4).filter(e => e).join('-')
}

export function isBlank (value) {
    return value !== true && isEmpty(value) && !isNumber(value) || isNaN(value);
}

export function isPresent (value) {
    return !isBlank(value)
}

export function isNilOrWhiteSpace (value) {
    return isEmpty(trim(value))
}

export function convertToCents(price) {
    const numberValue = toNumber(price)
    const cents = numberValue * 100
    const roundedCents = Math.round(cents)

    return roundedCents;
}

export function isAsLowAsVisible ({ price, isGiftCard }) {
    return !isGiftCard && price >= 50
}

export function isVariantDailyDeal (variantProperties) {
    const dailyDealPropValue = 'Daily Deals'

    if (Array.isArray(variantProperties)) {
        return variantProperties.some(variantProperty => {
            return variantProperty.property?.name === 'filter_feature' && variantProperty.value === dailyDealPropValue
        })
    }
    return variantProperties['filter_feature']?.includes(dailyDealPropValue) ?? false
}

export function isDailyDeal (variantProperties, layout) {
    return isVariantDailyDeal(variantProperties) && layout === 'cyberWeek'
}

export function updateFirstReferrerCookie (context, value) {
    updateReferrerCookie(context, 'sanFR', value)
}

export function updateLastReferrerCookie (context, value) {
    updateReferrerCookie(context, 'sanLR', value)
}

export function normalizedUSPhone (phone) {
    if (!phone) return null

    if (!phone.startsWith('+1')) phone = `+1${phone}`
    const parsed = parsePhoneNumberFromString(phone, 'US')
    if (!parsed?.isValid()) return null

    return parsed.format('E.164')
  }

function updateReferrerCookie (context, key, value) {
    context.$cookies.set(key, value, { path: '/', maxAge: 60 * 60 * 24 * 365 * 20 })
}