import Vue from 'vue'

function getInitParams({ ALGOLIA_APPLICATION_ID, ALGOLIA_API_KEY }) {
  return {
    appId: ALGOLIA_APPLICATION_ID,
    apiKey: ALGOLIA_API_KEY,
    useCookie: true,
  }
}

const onUserTokenChangeParams = {
  immediate: true
}

function onUserTokenChange (userToken) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ algoliaUserToken: userToken })
}

function setUserToken (userToken) {
  if (!userToken) { return }

  aa('setUserToken', userToken)
}

export default ({ store, $cookies, $config }) => {
    var ALGOLIA_INSIGHTS_SRC = 'https://cdn.jsdelivr.net/npm/search-insights@2.17.2/dist/search-insights.min.js'

    !function(e,a,t,n,s,i,c){e.AlgoliaAnalyticsObject=s,e[s]=e[s]||function(){
    (e[s].queue=e[s].queue||[]).push(arguments)},e[s].version=(n.match(/@([^\/]+)\/?/) || [])[1],i=a.createElement(t),c=a.getElementsByTagName(t)[0],
    i.async=1,i.src=n,c.parentNode.insertBefore(i,c)
    }(window,document,'script',ALGOLIA_INSIGHTS_SRC,'aa')

    aa('init', getInitParams($config))
    aa('onUserTokenChange', onUserTokenChange, onUserTokenChangeParams)
    setUserToken($cookies.get('sanUID'))

    store.subscribe((mutation) => {
      if (mutation.type === 'auth/setUser') {
        setUserToken($cookies.get('sanUID'))
      }
    })

    Vue.prototype.$searchInsights = aa
}
