// import { withSSRContext } from 'aws-amplify'

// export default async function fetchAccessToken(context) {
//   try {
//     const { Auth: AmplifySSRAuth } = withSSRContext(context)
//     const currentSession = await AmplifySSRAuth.currentSession()

//     return currentSession.accessToken.jwtToken
//   }
//   catch {
//     return null
//   }
// }

import { LOCAL_STORE_AUTH_TOKEN } from '@/store/auth'

function fetchCognitoAccessToken (context) {
  const webClientId = context.app.$config.COGNITO_USER_POOL_WEB_CLIENT_ID
  const accessTokenCookie = `CognitoIdentityServiceProvider.${webClientId}.LastAuthUser`
  const lastAuthUserId = context.$cookies.get(accessTokenCookie)
  if (!lastAuthUserId) return null

  const token = context.$cookies.get(`CognitoIdentityServiceProvider.${webClientId}.${lastAuthUserId}.accessToken`)

  return token && `Bearer ${token}`
}

function fetchDeviseToken (context) {
  return context.$cookies.get(LOCAL_STORE_AUTH_TOKEN)
}

export default function fetchAccessToken(context) {
  return fetchCognitoAccessToken(context) || fetchDeviseToken(context)
}