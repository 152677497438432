const middleware = {}

middleware['adbeacon_referrer'] = require('../middleware/adbeacon_referrer.js')
middleware['adbeacon_referrer'] = middleware['adbeacon_referrer'].default || middleware['adbeacon_referrer']

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['oauth_callback'] = require('../middleware/oauth_callback.js')
middleware['oauth_callback'] = middleware['oauth_callback'].default || middleware['oauth_callback']

middleware['redirect_signed_in'] = require('../middleware/redirect_signed_in.js')
middleware['redirect_signed_in'] = middleware['redirect_signed_in'].default || middleware['redirect_signed_in']

middleware['referrer'] = require('../middleware/referrer.js')
middleware['referrer'] = middleware['referrer'].default || middleware['referrer']

middleware['static_page'] = require('../middleware/static_page.js')
middleware['static_page'] = middleware['static_page'].default || middleware['static_page']

export default middleware
