import { mapGetters } from 'vuex'
import { pickBy } from 'lodash'

export default {
  computed: {
    ...mapGetters('auth', [
      'getEmail'
    ])
  },
  methods: {
    klaviyoIdentify() {
      let data = pickBy({ email: this.getEmail })
      this.klOnsite().push(['identify', data])
    },
    // We need to create a temporary array in which to store the events that will be sent to Klaviyo when the script is 
    // loaded and the user is identified.
    klOnsite () {
      window._klOnsite = window._klOnsite || []
      return window._klOnsite
    }
  }
}