import { startsWith } from 'lodash'

export default function ({ app, req, route, redirect }) {
  const referrer = req?.headers?.referer || req?.headers?.referrer || null
  if (!referrer) return

  const blogUrl = `${app.$config.baseUrl}/blog`
  if (!startsWith(referrer, blogUrl)) return

  const slug = referrer.replace(new RegExp(`^${blogUrl}/`), '') || 'homepage'
  const adBeaconParams = {
    admedium: 'referral',
    adsrc: 'blog',
    abid: slug
  }
  const adBeaconParamsApplied = Object.entries(adBeaconParams).every(([key, value]) => route.query[key] === value)
  if (adBeaconParamsApplied) return

  redirect({
    path: route.path,
    query: {
      ...route.query,
      ...adBeaconParams
    }
  })
}
