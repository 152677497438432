<template>
  <div class="grid-application-layout">
    <div v-if="checkUrl()" key="full-screen-layout" role="main" aria-label="Main Content">
      <modal/>

      <toast-info v-if="$store.getters['toast/getState']"/>

      <transition name="fade" class="grid-application-layout">
        <router-view/>
      </transition>
    </div>

    <div v-else key="normal-layout">
      <image-preview/>

      <modal/>

      <toast-info v-if="$store.getters['toast/getState']"/>

      <header-promotion v-if="this.$route.name !== 'checkout'"/>

      <a href="#navigation" class="visually-hidden-focusable">Skip to navigation</a>

      <header-wrapper v-if="this.$route.path !== '/40-k-sweepstakes/entry'"
                      class="application-header-wrapper">
        <lazy-hydrate when-idle>
          <the-header/>
        </lazy-hydrate>

        <lazy-hydrate when-visible>
          <div class="navigation-wrapper">
            <navigation/>
          </div>
        </lazy-hydrate>

      </header-wrapper>

      <a href="#main-content" class="visually-hidden-focusable">Skip to main content</a>

      <div id="main-content" class="content">
        <transition name="fade">
          <nuxt/>
        </transition>
      </div>

      <buy-more-get-more-banner v-if="buyMoreGetMoreBannerVisible"
                                @close="hideBuyMoreGetMoreBanner"/>

      <buy-more-save-more-banner v-if="buyMoreSaveMoreBannerVisible"
                                 @close="hideBuyMoreSaveMoreBanner"/>

      <client-only v-if="clientMounted">
        <cookie-policy/>
      </client-only>

      <a href="#footer" class="visually-hidden-focusable">Skip to footer</a>

      <lazy-hydrate when-visible>
        <the-footer v-if="showFooter" id="footer"/>
      </lazy-hydrate>

      <lazy-hydrate when-idle>
        <idle-loader/>
      </lazy-hydrate>

    </div>
  </div>

</template>

<script>

import { compact, last, kebabCase, camelCase, upperFirst } from 'lodash'
import { mapActions } from 'vuex'

import CookiePolicy from '@/components/CookiePolicy'
import HeaderPromotion from '@/components/HeaderPromotion'
import HeaderWrapper from '@/components/HeaderWrapper'
import ImagePreview from '@/components/ImagePreview'
import LazyHydrate from 'vue-lazy-hydration'
import Modal from '@/components/Modal'
import ToastInfo from '@/../shared/components/ToastInfo'

import BuyMoreGetMoreBannerMixin from '@/mixins/buyMoreGetMoreBanner'
import BuyMoreSaveMoreBannerMixin from '@/mixins/buyMoreSaveMoreBanner'
import KlaviyoIdentifyMixin from '@/mixins/klaviyo/identify'

export default {
  head() {
    return {
      title: 'Scout & Nimble'
    }
  },
  mixins: [
    BuyMoreGetMoreBannerMixin,
    BuyMoreSaveMoreBannerMixin,
    KlaviyoIdentifyMixin
  ],
  components: {
    Modal,
    ToastInfo,
    ImagePreview,
    CookiePolicy,
    HeaderPromotion,
    HeaderWrapper,
    LazyHydrate,
    IdleLoader: () => import('@/components/IdleLoader'),
    TheHeader: () => import('@/components/TheHeader'),
    TheFooter: () => import('@/components/TheFooter'),
    Navigation: () => import('@/components/Navigation'),
  },
  data() {
    return {
      clientMounted: false
    }
  },
  provide() {
    const appData = {}

    Object.defineProperty(appData, 'clientMounted', {
      enumerable: true,
      get: () => this.clientMounted
    })

    return {
      appData
    }
  },
  computed: {
    showFooter() {
      return !['/user/my_orders/processing', '/user/my_orders/history'].includes(this.$route.path)
    }
  },
  methods: {
    ...mapActions('globalData', ['setLayout', 'setNavigationDirection']),
    checkUrl() {
      if (this.$route.name === 'designers-account_verifying' ||
        this.$route.name === 'designers-account_verify' ||
        this.$route.name === 'designers-signup' ||
        this.$route.name === 'warehouse_quote-id-form' ||
        this.$route.name === 'new_payment' ||
        this.$route.name === 'info' ||
        this.$route.name === 'checkout' ||
        this.$route.name === 'checkout-new' ||
        this.$route.name === 'staging_auth') {
        return true
      } else {
        return false
      }
    },
    setAppLayout() {
      const validLayouts = [
        'cyberWeek', 'anniversarySale', 'laborDaySale', 'fallSale', 'christmas', 'blackFriday1', 'blackFriday2',
        'blackFriday3', 'blackFriday4'
      ]

      const layoutQuery = validLayouts.find(e => kebabCase(e) === this.$route.query?.layout)
      this.setLayout(layoutQuery)

      if (!!layoutQuery) {
        return localStorage.setItem('sanLayout', layoutQuery)
      } else {
        localStorage.removeItem('sanLayout')
      }

      const eligibleLayout = validLayouts.find(layout => !!this[`is${upperFirst(camelCase(layout))}Eligible`]?.())
      if (eligibleLayout) return this.setLayout(eligibleLayout)
    },
    isCyberWeekEligible () {
      return this.isInCdtTimeRange('2024-05-24T00:00:00', '2024-05-30T23:59:59')
    },
    isAnniversarySaleEligible () {
      return this.isInCdtTimeRange('2024-08-07T00:00:00', '2024-08-14T23:59:59')
    },
    isLaborDaySaleEligible () {
      return this.isInCdtTimeRange('2024-08-28T00:00:00', '2024-09-04T23:59:59')
    },
    isFallSaleEligible () {
      return this.isInCdtTimeRange('2024-10-07T00:00:00', '2024-10-14T23:59:59')
    },
    isChristmasEligible () {
      return this.isInCdtTimeRange('2024-10-15T00:00:00', '2024-10-22T23:59:59')
    },
    isBlackFriday1Eligible () {
      return this.isInCdtTimeRange('2024-11-01T00:00:00', '2024-11-07T23:59:59')
    },
    isBlackFriday2Eligible () {
      return this.isInCdtTimeRange('2024-11-08T00:00:00', '2024-11-20T23:59:59')
    },
    isBlackFriday3Eligible () {
      return this.isInCdtTimeRange('2024-11-21T00:00:00', '2024-11-24T23:59:59')
    },
    isBlackFriday4Eligible () {
      return this.isInCdtTimeRange('2024-11-25T00:00:00', '2024-11-29T23:59:59')
    },
    isInCdtTimeRange(startTimeString, endTimeString) {
      const currentDate = new Date()
      const cdtOffset = -300
      const utcOffset = currentDate.getTimezoneOffset()
      const cdtTime = new Date(currentDate.getTime() + (cdtOffset + utcOffset) * 60000)
      const startTime = new Date(startTimeString)
      const endTime = new Date(endTimeString)

      return cdtTime >= startTime && cdtTime <= endTime
    },
    setBackwardNavigationDirection() {
      this.setNavigationDirection('backward')
    },
    setGclid() {
      if (this.$route.query?.gclid) {
        console.log('gclid: ' + this.$route.query?.gclid)
        this.$store.dispatch('order/setGclid', this.$route.query?.gclid)
      }
    },
    setPromoCode () {
      this.$store.dispatch('order/setPromoCode', this.getQueryParamValue('promo_code'))
    },
    async setUrlCampaign () {
      await this.$store.dispatch('order/setUrlCampaign', this.getQueryParamValue('url_campaign'))
    },
    getQueryParamValue (key) {
      let value = this.$route.query?.[key]
          value = [value].flat()
          value = compact(value)

      return last(value)
    },
    setHoneybadgerNotificationsFiltering () {
      this.$honeybadger?.beforeNotify(() => {
        return !/Googlebot|Instagram|Applebot|Bingbot|Storebot|YandexBot|AdsBot/i.test(navigator.userAgent)
      })
    }
  },
  async fetch() {
    this.setPromoCode()

    this.$store.dispatch('auth/initAuth', this.$root.context)
    let requests = compact([
      this.$store.dispatch('auth/initialUser'),
      !!this.$store.getters['order/getOrderId'] && this.$store.dispatch('order/initOrder')
    ])

    await Promise.all(requests)
  },
  beforeDestroy () {
    if (process.server) return
    window.removeEventListener('popstate', this.setBackwardNavigationDirection)
    this.clearBuyMoreGetMoreExpiredTimer()
    this.clearBuyMoreSaveMoreExpiredTimer()
  },
  async mounted () {
    await this.setUrlCampaign()
    this.checkBuyMoreGetMoreBannerExpired()
    this.checkBuyMoreSaveMoreBannerExpired()
    this.setHoneybadgerNotificationsFiltering()
    this.clientMounted = true
    this.setAppLayout()
    this.setGclid()
    this.klaviyoIdentify()
    window.addEventListener('popstate', this.setBackwardNavigationDirection)
  }
}

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
@import '@/assets/stylesheets/variables.scss';
@import '@/assets/stylesheets/animations/fade';

.layout-border-top {
  border-top: 11px solid $top-layout-color;
}

.visually-hidden-focusable {
  position: absolute;
  top: -9999px;
  left: -9999px;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.content {
  min-height: 350px;
  margin-bottom: 50px;
}

$header-wrapper-width: calc(100vw - 15px);

.application-header-wrapper {
  width: $header-wrapper-width;

  /deep/ .header-wrapper__inner {
    top: 0;
    left: 0;
    z-index: 20;
    background: #fff;
    width: $header-wrapper-width;

    &.top {
      .navigation-wrapper {
        border-bottom: 1px solid $border-line;
        margin-bottom: 3px;
      }
    }
  }

  /deep/ .header-wrapper__placeholder {
    width: $header-wrapper-width;
  }
}


@media only screen and (max-width: $tablet-max) {
  .navigation-wrapper {
    display: none;
  }

  .header-wrapper {
    /deep/ &__inner {
      padding: 0 !important;
      width: 100vw !important;
    }

    /deep/ &__placeholder {
      width: 100vw !important;
    }
  }
}

</style>

