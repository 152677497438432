import { formatRailsErrors } from '@/utils/utils'

export default function ({ app, store, $config: { GOOGLE_AUTH_CLIENT_ID } }) {
  const GOOGLE_SCRIPT_ID = 'google-signin-script'

  async function initGoogleOneTap () {
    try {
      await loadGoogleScript()
      initializeClient()
      togglePrompt(isSignedIn())
      watchSignedIn()
    } catch (error) {
      console.error('Google Sign-In script failed to load', error)
    }
  }

  function initializeClient () {
    google.accounts.id.initialize({
      client_id: GOOGLE_AUTH_CLIENT_ID,
      callback: signInCallback,
      cancel_on_tap_outside: false,
      use_fedcm_for_prompt: true
    })
  }

  function watchSignedIn () {
    store.watch(isSignedIn, newValue => togglePrompt(newValue))
  }

  function isSignedIn () {
    return store.getters['auth/signedIn']
  }

  function togglePrompt (signedIn) {
    signedIn ? google.accounts.id.cancel() : google.accounts.id.prompt()
  }

  async function signInCallback (response) {
    try {
      await store.dispatch('auth/signOut', { skipRedirect: true })
      await store.dispatch('auth/googleOneTapSignIn', response)
      await store.dispatch('auth/initialUser')
      if (!!store.getters['order/getOrderId']) store.dispatch('order/initOrder')
    }
    catch (error) {
      console.error(error)
      store.dispatch('modal/showModal', {
        component: 'Information',
        props: {
          info: formatRailsErrors(error.response.data.errors).join('<br>')
        }
      })
      app.router.replace({ name: 'index' })
    }
  }

  function loadGoogleScript () {
    return new Promise((resolve, reject) => {
      if (document.getElementById(GOOGLE_SCRIPT_ID)) return resolve()

      const script = document.createElement('script')
      script.src = 'https://accounts.google.com/gsi/client'
      script.id = GOOGLE_SCRIPT_ID
      script.async = true
      script.defer = true
      script.onload = resolve
      script.onerror = reject
      document.head.appendChild(script)
    })
  }

  initGoogleOneTap()
}