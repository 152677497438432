import * as api_config from '../../config/api_config'

export default $axios => ({
    cognitoSignOut() {
        return $axios.delete('users/cognito_sign_out', {
            headers: api_config.HEADERS
        })
    },
    cognitoSignIn(params) {
      return $axios.get('users/auth/cognito/callback', {
          params: params,
          baseURL: '/'
      })
    },
    googleOneTapSignIn(params) {
        return $axios.post('/users/auth/google/onetap', params)
    }
})