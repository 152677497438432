import ApiOrder from '@/api/Order'

import { cloneDeep, compact, round } from 'lodash'
import CouponCodeApplication from '@/utils/couponCodeApplication'
import DiscountGeneration from '@/utils/discountGeneration'

import GtmHelpers from '@/mixins/gtmHelpers'
import PinterestConversionEvents from '@/mixins/pinterestConversionEvents'

export const LOCAL_STORE_ORDER_ID = 'sanOID'
export const LOCAL_STORE_GCLID = 'sanGCLID'
export const LOCAL_STORE_PROMO_CODE = 'sanPC'
export const LOCAL_STORE_URL_CAMPAIGN = 'sanUC'
export const LOCAL_STORE_URL_CAMPAIGN_USER_EMAIL = 'sanUCUE'
export const LOCAL_STORE_URL_CAMPAIGN_EXPIRES_AT = 'sanUCEA'
export const LOCAL_STORE_URL_CAMPAIGN_APPLICATION_DISABLED = 'sanUCAD'

function buildGtmLineItem (lineItem, quantity) {
  return {
    ...lineItem,
    name: lineItem.product_name,
    quantity
  }
}

function sendGtmChangeCartEvent(lineItem, quantity) {
    if (quantity === 0) { return }

    let total = round(parseFloat(lineItem.price) * quantity, 2)
    let gtmLineItem = buildGtmLineItem(lineItem, Math.abs(quantity))

    if (quantity > 0) {
        GtmHelpers.methods.gtmAddToCart(total, gtmLineItem)
    } else {
        GtmHelpers.methods.gtmRemoveFromCart(-total, gtmLineItem)
    }
}

function sendPinterestAddToCartEvent(context, lineItem, quantity) {
    if (quantity <= 0) return

    const product = {
      total: round(parseFloat(lineItem.price) * quantity, 2),
      name: lineItem.product_name,
      category: lineItem.category,
      brand: lineItem.supplier_name,
      sku: lineItem.variant.sku,
      price: lineItem.price,
      quantity
    }

    PinterestConversionEvents.methods.pinterestAddToCart(context.state.order, product)
}

function getOrderGiftCards (state) {
    return state.order?.order_gift_cards || []
}

function setUrlCampaignExpiresAtCookie (context, urlCampaign, maxAge) {
    if ([/*'bmgm-tier', */'bmsv-tier', 'bmsv-tier_subscriber'].includes(urlCampaign)) {
        const expires = new Date(Date.now() + (maxAge * 1000))
        context.$cookies.set(LOCAL_STORE_URL_CAMPAIGN_EXPIRES_AT, expires.toISOString(), { path: '/', maxAge })
    } else {
        context.$cookies.remove(LOCAL_STORE_URL_CAMPAIGN_EXPIRES_AT)
    }
}

function setUrlCampaignCookies (context, urlCampaign) {
    if (!urlCampaign) return

    context.$cookies.remove(LOCAL_STORE_URL_CAMPAIGN_APPLICATION_DISABLED)

    const setUrlCampaignCookie = context.$cookies.get(LOCAL_STORE_URL_CAMPAIGN)
    if (setUrlCampaignCookie === urlCampaign) return

    const maxAgeDays = [/*'bmgm-tier', */'bmsv-tier', 'bmsv-tier_subscriber'].includes(urlCampaign) ? 2 : 90
    const maxAge = maxAgeDays * 24 * 60 * 60

    context.$cookies.set(LOCAL_STORE_URL_CAMPAIGN, urlCampaign, { path: '/', maxAge })
    setUrlCampaignExpiresAtCookie(context, urlCampaign, maxAge)
    context.dispatch('order/clearPromoCode')
}

function showModalIfCampaignMatches(context, urlCampaign, campaignType, modalComponent, modalProps = {}) {
    if (process.server) return
    if (urlCampaign !== campaignType) return
    if (urlCampaign === context.$cookies.get(LOCAL_STORE_URL_CAMPAIGN)) return
    if (urlCampaign === context.getters['order/getUrlCampaign']) return

    return new Promise(resolve => {
      context.dispatch('modal/showModal', {
        component: modalComponent,
        classes: ['auto-width'],
        props: {
            onCloseFunction: resolve,
            ...modalProps
        }
      })
    })
  }

async function showBuyMoreGetMoreModalIfBmgm(context, urlCampaign) {
    return
    await showModalIfCampaignMatches(
        context,
        urlCampaign,
        'bmgm-tier',
        'BuyMoreGetMoreModal'
    )
}

async function showBuyMoreSaveMoreModalIfBmsv(context, urlCampaign) {
    await showModalIfCampaignMatches(
        context,
        urlCampaign,
        'bmsv-tier',
        'BuyMoreSaveMoreModal',
        { subscriber: false }
    )
}

async function showBuyMoreSaveMoreModalIfBmsvSubscriber(context, urlCampaign) {
    await showModalIfCampaignMatches(
        context,
        urlCampaign,
        'bmsv-tier_subscriber',
        'BuyMoreSaveMoreModal',
        { subscriber: true }
    )
}

export const state = () => ({
        loading: false,
        error: false,
        order_id: null,
        order: null,
        gclid: null,
        promoCode: null,
        urlCampaign: null
    })

export const getters = {
        getOrderId: state => {
            return state.order_id
        },
        getOrder: state => {
            return state.order
        },
        getGclid: state => {
            return state.gclid
        },
        getPromoCode: state => {
            return state.promoCode
        },
        getUrlCampaign: state => {
            return state.urlCampaign
        },
        getShipments: state => {
            return state.order ? state.order.shipments : {}
        },
        getVisibleShipments: state => {
            return state.order ? state.order.visible_shipments : {}
        },
        getShippingCostZipcodeFee: (state, getters) => {
            return parseFloat(getters.getAdjustments?.find(x => x.label === 'Shipping Cost Zipcode Fee')?.amount ?? 0)
        },
        getWhiteGloveAdjustment: (_state, getters) => {
            return parseFloat(getters.getAdjustments?.find(x => x.label === 'White Glove Shipping')?.amount ?? 0)
        },
        getShipmentCost: state => shipment_type => {
            return state.order ? state.order.shipments[shipment_type]['cost'] : 0
        },
        getLineItems: state => {
            return state.order ? state.order.line_items : []
        },
        getLineItemsTotal: state => {
            return state.order ? state.order.line_items_total : 0
        },
        getShipmentsTotal: (state, getters) => {
            return state.order ? state.order.shipments_total - getters.getServiceLevelTotal : 0
        },
        getServiceLevelTotal: (_state, getters) => {
            return getters.getWhiteGloveAdjustment
        },
        getAdjustmentsTotal: state => {
            return state.order ? state.order.adjustments_total : 0
        },
        getTaxAdjustmentsTotal: state => {
            return state.order ? state.order.tax_adjustments_total : 0
        },
        getBestPriceOptimizationAdjustments: (_state, getters) => {
            return getters.getAdjustments?.filter(adjustment => adjustment.best_price_optimization) || []
        },
        getBestPriceOptimizationAdjustmentsTotal: state => {
            return state.order ? state.order.best_price_optimization_adjustments_total : 0
        },
        getBestPriceOptimizationApplied: (_state, getters) => {
            return !!getters.getBestPriceOptimizationAdjustmentsTotal
        },
        getTotalWithoutBestPriceOptimization: state => {
            return state.order ? state.order.total_without_best_price_optimization : 0
        },
        getNotTaxAdjustmentsTotal: state => {
            return state.order ? state.order.not_tax_adjustments_total : 0
        },
        getTotal: state => {
            return state.order ? state.order.total : 0
        },
        getAdjustments: state => {
            return state.order ? state.order.adjustments : []
        },
        getNotTaxAdjustments: state => {
            return state.order ? state.order.not_tax_adjustments : []
        },
        getTaxAdjustments: state => {
            return state.order ? state.order.tax_adjustments : []
        },
        getShippingAddress: state => {
            return state.order ? state.order.shipping_address : {}
        },
        getBillingAddress: state => {
            return state.order ? state.order.billing_address : {}
        },
        loading: state => {
            return state.loading
        },
        error: state => {
            return state.error
        },
        getCartCordial: state => {
            return (state.order && state.order.cordial_cart) ? state.order.cordial_cart['products'] : {}
        },
        getOrderCordial: state => {
            return (state.order && state.order.cordial_cart) ? state.order.cordial_cart['order'] : {}
        },
        getOrderGiftCards,
        getShippingCostRate: state => {
            return state.order.shipping_cost_rate
        },
        getSpecialDeliveryInfo: state => {
            return state.order?.special_delivery_info
        },
        getDiscountsAdjustments: state => {
            return state.order?.discounts_adjustments || []
        },
        getGiftCardsAdjustments: state => {
            let adjustments = state.order?.gift_cards_adjustments || []
            adjustments = adjustments.map(adjustment => {
                let orderGiftCard = getOrderGiftCards(state).find(({ id }) => id === adjustment.order_gift_card_id)
                if (!orderGiftCard) return

                return {
                    ...adjustment,
                    balance: orderGiftCard.balance,
                    code: orderGiftCard.hidden_code
                }
            })

            return compact(adjustments)
        },
        getDiscountsAdjustmentsTotal: state => {
            return state.order?.discounts_adjustments_total || 0
        },
        getGiftCardsAdjustmentsTotal: state => {
            return state.order?.gift_cards_adjustments_total || 0
        },
        getMissingAmountToFreeShipping: state => state.order?.missing_amount_to_free_shipping || 0,
        getAppliedPromotionName: ({ order }) => order?.promotion?.name,
        getExcludedLineItems: state => state.order?.promotion?.excluded_line_items || [],
        getEligibleLineItemsTotalPrice: state => +(state.order?.promotion?.eligible_line_items_total_price || 0),
        getBestPriceOptimizationExcludedLineItems: state => {
            return state.order?.promotion?.best_price_optimization_excluded_line_items || []
        },
        getGiftPromotions: state => state.order?.gift_promotions || [],
        getPromotion: state => state.order?.promotion,
        getIsFinalShippingCost: state => state.order?.is_final_shipping_cost,
        getVisibleTotal (_state, { getIsFinalShippingCost, getTotal, getShipmentsTotal }) {
            if (!getIsFinalShippingCost) return parseFloat(getTotal) - parseFloat(getShipmentsTotal)

            return getTotal
        }
    }
export const mutations = {
        setOrder(state, payload) {
            state.order = payload
        },
        setOrderId(state, order_id) {
            state.order_id = order_id
        },
        clearOrder(state) {
            state.order = null
        },
        clearOrderId(state) {
            state.order_id = null
        },
        setGclid(state, gclid) {
            state.gclid = gclid
        },
        setPromoCode(state, promoCode) {
            state.promoCode = promoCode
        },
        setUrlCampaign(state, urlCampaign) {
            state.urlCampaign = urlCampaign
        },
        clearPromoCode(state) {
            state.promoCode = null
        },
        clearUrlCampaign(state) {
            state.urlCampaign = null
        },
        loading(state, loading) {
            state.loading = loading
        },
        error(state, error) {
            state.error = error
        }
    }
export const actions = {
        initOrderData({ commit }, context) {
            commit('setOrderId', context.$cookies.get(LOCAL_STORE_ORDER_ID))
        },
        setOrderId({commit}, order_id) {
            this.$cookies.set(LOCAL_STORE_ORDER_ID, order_id, { path: '/', maxAge: 2147483647 })
            commit('setOrderId', order_id)
        },
        clearOrderId({commit}) {
            this.$cookies.remove(LOCAL_STORE_ORDER_ID)
            commit('clearOrderId')
        },
        setOrder({commit}, payload) {
            commit('setOrder', payload)
        },
        clearOrder({commit}) {
            commit('clearOrder')
        },
        setGclid({commit}, gclid) {
            this.$cookies.set(LOCAL_STORE_GCLID, gclid, { path: '/', maxAge: 7776000 })
            commit('setGclid', gclid)
        },
        setPromoCode({ commit, dispatch }, promoCode) {
            if (!!promoCode) {
                this.$cookies.set(LOCAL_STORE_PROMO_CODE, promoCode, { path: '/', maxAge: 7776000 })
                dispatch('clearUrlCampaign')
            }
            commit('setPromoCode', promoCode || this.$cookies.get(LOCAL_STORE_PROMO_CODE))
        },
        // Banner should be displayed only after modal is closed, so we hold the state setting until then
        async setUrlCampaign({ commit }, urlCampaign) {
            await Promise.all([
                showBuyMoreGetMoreModalIfBmgm(this, urlCampaign),
                showBuyMoreSaveMoreModalIfBmsv(this, urlCampaign),
                showBuyMoreSaveMoreModalIfBmsvSubscriber(this, urlCampaign),
                setUrlCampaignCookies(this, urlCampaign)
            ])

            commit('setUrlCampaign', urlCampaign || this.$cookies.get(LOCAL_STORE_URL_CAMPAIGN))
        },
        clearPromoCode({ commit }) {
            this.$cookies.remove(LOCAL_STORE_PROMO_CODE)
            commit('clearPromoCode')
        },
        clearUrlCampaign({ commit }) {
            this.$cookies.remove(LOCAL_STORE_URL_CAMPAIGN)
            this.$cookies.remove(LOCAL_STORE_URL_CAMPAIGN_EXPIRES_AT)
            this.$cookies.remove(LOCAL_STORE_URL_CAMPAIGN_APPLICATION_DISABLED)

            commit('clearUrlCampaign')
        },
        loading({commit}, loading) {
            commit('loading', loading)
        },
        error({commit}, error) {
            commit('error', error)
        },
        //async actions to manage order
        initOrder({ state, dispatch, rootGetters }) {
            return new Promise((resolve, reject) => {
                dispatch('loading', true)

                const oldConfig = cloneDeep(rootGetters['axiosConfig/getConfig'])
                dispatch('axiosConfig/setConfig', { handle404: false }, { root: true })

                return ApiOrder.getOrder(this.$axios, {order_id: state.order_id})
                    .then(response => {
                        dispatch('setOrder', response.data.data)
                        dispatch('loading', false)
                        resolve(response)
                        const context = { ...this, store: this }
                        CouponCodeApplication(context)()
                        DiscountGeneration(context)()
                    })
                    .catch(error => {
                        console.error(error)
                        dispatch('clearOrderId')
                        dispatch('clearOrder')
                        dispatch('loading', false)
                        reject(error)
                    })
                    .finally(() => {
                        dispatch('axiosConfig/setConfig', oldConfig, { root: true })
                    })
            })
        },
        changeQuantityBtn(context, payload) {
            const { state, dispatch, rootGetters } = context
            let add = payload.add
            let line_item_id = payload.line_item_id

            let line_item = state.order.line_items.filter(li => li.id == line_item_id)[0]

            if (line_item == undefined) return

            let quantity = line_item.quantity
            let quantity_change = add ? 1 : -1
            let new_quantity = quantity + quantity_change

            sendGtmChangeCartEvent(line_item, quantity_change)
            sendPinterestAddToCartEvent(context, line_item, quantity_change)

            dispatch('loading', true)

            const oldConfig = cloneDeep(rootGetters['axiosConfig/getConfig'])
            dispatch('axiosConfig/setConfig', { handle404: false }, { root: true })

            ApiOrder.updateQuantity(this.$axios, {
                line_item_id: line_item_id,
                quantity: new_quantity,
                order_id: state.order_id
            })
                .then(response => {
                    dispatch('setOrder', response.data.data)
                    dispatch('loading', false)
                })
                .catch(error => {
                    dispatch('clearOrderId')
                    dispatch('clearOrder')
                    if(new_quantity === 0) {

                        dispatch('modal/hideModal', null, {root: true})
                    }
                    dispatch('loading', false)
                })
                .finally(() => {
                    dispatch('axiosConfig/setConfig', oldConfig, { root: true })
                })
        },
        removeItem({state, dispatch, rootGetters}, line_item_id) {
            let line_item = state.order.line_items.find(li => li.id === line_item_id)
            sendGtmChangeCartEvent(line_item, -line_item.quantity)

            dispatch('loading', true)

            const oldConfig = cloneDeep(rootGetters['axiosConfig/getConfig'])
            dispatch('axiosConfig/setConfig', { handle404: false }, { root: true })

            ApiOrder.updateQuantity(this.$axios, {
                line_item_id: line_item_id,
                quantity: 0,
                order_id: state.order_id
            })
                .then(response => {
                    dispatch('setOrder', response.data.data)
                    dispatch('loading', false)
                })
                .catch(error => {
                    dispatch('clearOrderId')
                    dispatch('clearOrder')
                    // hiding modal
                    dispatch('modal/hideModal', null, {root: true})
                    dispatch('loading', false)
                })
                .finally(() => {
                    dispatch('axiosConfig/setConfig', oldConfig, { root: true })
                })
        }
}
