import 'twix'
import moment from 'moment'
import { startCase, upperFirst, get, flatten } from 'lodash'
import shippingCategories from '@/../shared/constants/shippingCategories'

function getShippingCategoryLabel (name) {
    return get(shippingCategories.find(e => e.name === name), 'label')
}

function getBreadcrumbFromPermalink (permalink) {
    return permalink
        .replace(/\//g, ' / ')
        .replace(/-and-/g, ' & ')
        .replace(/-/g, ' ')
        .replace(/[^\s]+/g, upperFirst)
}

function getValueFromPermalink (permalink) {
    return permalink.replace(/\//g, ' > ')
}

function getNormalizedPhone (phone) {
    return phone?.replace(/(^\+1|[^\d])/g, '')
}

function getDeliveryWindowDate (deliveryTime) {
    deliveryTime = flatten([deliveryTime])
    let deliveryTimeFrom = getMomentDate(deliveryTime[0])
    let deliveryTimeTo = getMomentDate(deliveryTime[1])

    if (deliveryTimeFrom && deliveryTimeTo && deliveryTimeFrom.unix() !== deliveryTimeTo.unix()) {
        return deliveryTimeFrom.twix(deliveryTimeTo).format({ implicitYear: false })
    } else if (deliveryTimeFrom) {
        return deliveryTimeFrom.format('MMM D, YYYY, HH:mm A')
    } else {
        return deliveryTime[0]
    }
}

function getMomentDate (date) {
  if (!date) { return null }

  let momentDate = moment(date)
  if (!momentDate.isValid()) { return null }

  return momentDate
}

function getVisibleEstAvailabilityText (date) {
    if (isPastDate(date)) return 'Contact us for availability'

    return date
}

function isPastDate (date) {
    const momentDate = moment.utc(date, 'MMM D, YYYY')
    if (!momentDate.isValid()) return false

    const currentDate = moment()
    const weekDiff = currentDate.diff(date, 'weeks')

    return weekDiff >= 2
}

function createBreadcrumbs (permalink) {
    const parts = permalink.split('/')
    let cumulativePermalink = ''

    return parts.map((part, index) => {
      index === 0
        ? cumulativePermalink = part
        : cumulativePermalink += `/${part}`

      return {
        name: startCase(part.replace(/-/g, ' ')).replace(/\band\b/gi, '&'),
        permalink: cumulativePermalink
      }
    })
}

async function sha256Hash (data) {
    if (!data) return

    const encoder = new TextEncoder()
    const encodedData = encoder.encode(data.trim())
    const hashBuffer = await crypto.subtle.digest('SHA-256', encodedData)
    const result =
        Array.from(new Uint8Array(hashBuffer))
             .map(byte => byte.toString(16).padStart(2, '0'))
             .join('')

    return result
}

function calculateDiscountPercentage (price, salePrice) {
    return ((1 - (salePrice / price).toFixed(2)) * 100).toFixed(0)
}

export {
    calculateDiscountPercentage,
    createBreadcrumbs,
    getBreadcrumbFromPermalink,
    getDeliveryWindowDate,
    getMomentDate,
    getNormalizedPhone,
    getShippingCategoryLabel,
    getValueFromPermalink,
    getVisibleEstAvailabilityText,
    isPastDate,
    sha256Hash,
    shippingCategories
}
